/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import { Spin, Table } from 'antd'
import { DATA_LIST_TEAM } from 'config/constants/game'
import { formatCode } from 'helpers/CommonHelper'
import useGetBunnyGame from 'hooks/useGetBunnyGame'
import { useCallback, useEffect, useState } from 'react'
import styled from 'styled-components'
import { formatNumber } from 'utils/formatBalance'
import { Link } from 'widgets'
import Image from 'next/image'

const WrapTablePredict = styled.div`
  padding: 20px 0px;

  ${({ theme }) => theme.mediaQueries.md} {
    padding: 20px;
  }

  ${({ theme }) => theme.mediaQueries.lg} {
    padding: 44px;
  }

  .ant-table {
    background-color: transparent;

    thead > tr {
      > th {
        border-top: 1px solid #8d8d8d;
        border-bottom: 1px solid #8d8d8d;
        background-color: transparent;
      }

      &:first-child {
        th {
          &:first-child {
            border-left: 1px solid #8d8d8d;
            border-top-left-radius: 12px;
            border-bottom-left-radius: 12px;
          }

          &:last-child {
            border-right: 1px solid #8d8d8d;
            border-top-right-radius: 12px;
            border-bottom-right-radius: 12px;
          }
        }
      }

      .ant-table-cell {
        color: #bfbfbf;
        font-size: 14px;
        line-height: 150%;
        font-style: normal;
        font-weight: 600;

        ${({ theme }) => theme.mediaQueries.lg} {
          font-size: 16px;
          line-height: 24px;
        }
      }
    }

    tbody > tr {
      > td {
        border-bottom: none;
      }

      &.ant-table-row:hover > td {
        background-color: rgba(255, 255, 255, 0.025);
      }

      .ant-table-cell {
        color: #fff;
        font-size: 14px;
        line-height: 150%;
        font-style: normal;
        font-weight: 500;

        ${({ theme }) => theme.mediaQueries.lg} {
          font-size: 16px;
          line-height: 24px;
        }
      }
    }
  }

  .box-control {
    display: flex;
    margin-bottom: 30px;

    button {
      padding: 8px 16px;
      color: #fff;
      background: transparent;
      border-radius: 50px;
      border: 1px solid #ffe15a;

      &.active {
        color: #000;
        background: #ffe15a;
      }

      &:not(:last-child) {
        margin-right: 16px;
      }
    }
  }
`

const columnsOperatorAddress = ({ getDataGame }: { getDataGame: (input: string) => void }) => [
  {
    title: 'Id',
    dataIndex: 'id',
    render: (_, record) => (
      <div className="text-center" onClick={() => getDataGame(record?.team?.id)} style={{ cursor: 'pointer' }}>
        <p>{formatCode(record?.team?.id, 5, 5)}</p>
      </div>
    ),
  },
  {
    title: 'Match',
    dataIndex: 'teamPredict',
    render: (id, record) => (
      <div className="text-center">
        <div className="flex items-center gap-2">
          <Image src={record?.team?.teamALogo} width={30} height={30} />
          <p>{record?.team?.teamA}</p>
        </div>
        vs
        <div className="flex items-center gap-2">
          <Image src={record?.team?.teamBLogo} width={30} height={30} />
          <p>{record?.team?.teamB}</p>
        </div>
      </div>
    ),
  },
  {
    title: 'Predicted',
    dataIndex: 'teamPredict',
    render: (id, record) =>
      record?.predictType === 'SCORE' ? (
        <div className="w-fit px-4 py-1 bg-[#383838] rounded-[100px] m-auto">
          {record?.score?.scoreA} - {record?.score?.scoreB}
        </div>
      ) : (
        <>
          {id === 0 ? (
            <div className="w-fit px-4 py-1 bg-[#383838] rounded-[100px] m-auto">Draw</div>
          ) : (
            <div className="flex items-center gap-3">
              <Image src={DATA_LIST_TEAM?.find((item) => +item?.id === +id)?.logo} width={30} height={30} />
              <p className="text-base font-[500]">{DATA_LIST_TEAM?.find((item) => +item?.id === +id)?.name}</p>
            </div>
          )}
        </>
      ),
  },
  {
    title: 'Account',
    dataIndex: 'walletAddress',
    key: 'walletAddress',
    render: (text, record) => (
      <div>
        <Link
          href={
            record?.chain === 'BNB_CHAIN'
              ? `https://bscscan.com/address/${text}`
              : `https://chiliscan.com/address/${text}`
          }
        >
          {formatCode(text, 5, 5)}
        </Link>
      </div>
    ),
  },
  {
    title: 'Chain',
    dataIndex: 'chain',
    key: 'chain',
    render: (text) => <div>{text === 'BNB_CHAIN' ? 'BNB' : 'CHZ'}</div>,
  },
  {
    title: 'Amount',
    dataIndex: 'predicted',
    key: 'predicted',
    render: (text, record) => (
      <div>
        {formatNumber(text)}{' '}
        {record?.tokenAddress === '0x0000000000000000000000000000000000000000'
          ? record?.chain === 'BNB_CHAIN'
            ? 'BNB'
            : 'CHZ'
          : 'BUNNY'}
      </div>
    ),
  },
  {
    title: 'Rate',
    dataIndex: 'status',
    key: 'status',
    render: (_, record) => (
      <div>
        x
        {record?.predictType === 'SCORE'
          ? record?.score?.winRate
          : record?.teamPredict === 0
          ? `${record?.team?.drawRate}`
          : `${
              record?.team?.teamA === DATA_LIST_TEAM?.find((item) => +item?.id === +record?.teamPredict)?.name
                ? record?.team?.winRateTeamA
                : record?.team?.winRateTeamB
            }`}
      </div>
    ),
  },
  {
    title: 'Amount Win',
    dataIndex: 'predicted',
    key: 'predicted',
    render: (text, record) =>
      (record?.status === 'WIN' || record?.status === 'CLAIMED') && (
        <div>
          {formatNumber(
            +record?.predicted *
              +(record?.predictType === 'SCORE'
                ? record?.score?.winRate
                : record?.teamPredict === 0
                ? `${record?.team?.drawRate}`
                : `${
                    record?.team?.teamA === DATA_LIST_TEAM?.find((item) => +item?.id === +record?.teamPredict)?.name
                      ? record?.team?.winRateTeamA
                      : record?.team?.winRateTeamB
                  }`),
          )}{' '}
          {record?.tokenAddress === '0x0000000000000000000000000000000000000000'
            ? record?.chain === 'BNB_CHAIN'
              ? 'BNB'
              : 'CHZ'
            : 'BUNNY'}
        </div>
      ),
  },
  {
    title: 'Hash',
    dataIndex: 'transactionHash',
    key: 'transactionHash',
    render: (text, record) => (
      <div>
        <Link
          href={record?.chain === 'BNB_CHAIN' ? `https://bscscan.com/tx/${text}` : `https://chiliscan.com/tx/${text}`}
        >
          {formatCode(text, 5, 5)}
        </Link>
      </div>
    ),
  },
]

const TablePredict = ({ setModalGame }: { setModalGame: (input: any) => void }) => {
  const [dataHistory, setDataHistory] = useState<any>()
  const [paramSearchHistory, setParamSearchHistory] = useState<any>()
  const [loadingGetHistory, setLoadingGetHistory] = useState<boolean>(false)
  const [type, setType] = useState<'ALL' | 'WIN' | 'LOSE' | 'PREDICTED' | 'CLAIMED'>('WIN')

  const { fetchSearchHistoryTransaction, fetchInfoGame } = useGetBunnyGame()

  const getDataGame = useCallback(
    (idGame: string) => {
      fetchInfoGame(idGame, (data) => {
        setModalGame({
          toggle: true,
          type: 'Edit',
          data,
        })
      })
    },
    [fetchInfoGame, setModalGame],
  )

  useEffect(() => {
    setLoadingGetHistory(true)
    if (type !== 'ALL') {
      setParamSearchHistory({
        skip: 0,
        limit: 10,
        sort: { field: 'created_at', order: -1 },
        filter: [
          {
            name: 'status',
            value: type,
            operator: 'eq',
          },
        ],
      })
    } else {
      setParamSearchHistory({
        skip: 0,
        limit: 10,
        sort: { field: 'created_at', order: -1 },
      })
    }
  }, [type])

  const getDataHistory = useCallback(() => {
    fetchSearchHistoryTransaction(paramSearchHistory, (data) => {
      setDataHistory(data)
      setLoadingGetHistory(false)
    })
  }, [fetchSearchHistoryTransaction, paramSearchHistory])

  useEffect(() => {
    if (paramSearchHistory) {
      setLoadingGetHistory(true)
      getDataHistory()
    }
  }, [getDataHistory, paramSearchHistory])

  return (
    <WrapTablePredict>
      <div className="box-control">
        <button type="button" className={type === 'ALL' ? 'active' : ''} onClick={() => setType('ALL')}>
          All
        </button>
        <button type="button" className={type === 'WIN' ? 'active' : ''} onClick={() => setType('WIN')}>
          Win
        </button>
        <button type="button" className={type === 'LOSE' ? 'active' : ''} onClick={() => setType('LOSE')}>
          Lose
        </button>
        <button type="button" className={type === 'PREDICTED' ? 'active' : ''} onClick={() => setType('PREDICTED')}>
          Predicted
        </button>
        <button type="button" className={type === 'CLAIMED' ? 'active' : ''} onClick={() => setType('CLAIMED')}>
          Claimed
        </button>
      </div>

      {!loadingGetHistory ? (
        <Table
          dataSource={dataHistory?.docs}
          columns={columnsOperatorAddress({ getDataGame })}
          rowKey={(ojb) => ojb.blockNumber}
          pagination={{
            current: paramSearchHistory?.skip ? paramSearchHistory?.skip / paramSearchHistory?.limit + 1 : 1,
            total: dataHistory?.totalDocs,
            pageSize: paramSearchHistory?.limit,
            onChange: (data) => {
              setParamSearchHistory((prev) => ({
                ...prev,
                skip: prev.limit * data - prev.limit,
              }))
            },
            onShowSizeChange: (current, pageSize) => {
              setParamSearchHistory((prev) => ({
                ...prev,
                limit: pageSize,
              }))
            },
          }}
          scroll={{ x: 1000 }}
        />
      ) : (
        <Spin />
      )}
    </WrapTablePredict>
  )
}

export default TablePredict
