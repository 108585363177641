/* eslint-disable camelcase */
import { useCallback } from 'react'
import axios from 'axios'
import { API_PAYX } from 'config/constants/endpoints'

const useGetBunnyGame = () => {
  const fetchData = useCallback(async (payload: any, cbs?: (res: any) => void, cbe?: (res: any) => void) => {
    try {
      await axios.post(`${API_PAYX}/teams/search`, payload).then((res) => {
        if (res.status === 201 || res.status === 200) {
          if (cbs) cbs(res?.data?.data)
        }
        if (cbe) cbe(res)
      })
    } catch (error) {
      console.error('🚀 ~ fetchLocation ~ error:', error)
    }
  }, [])

  const fetchInfoGame = useCallback(async (id: string, cbs?: (res: any) => void, cbe?: (res: any) => void) => {
    try {
      await axios.get(`${API_PAYX}/teams/${id}`).then((res) => {
        if (res.status === 200 || res.status === 201) {
          if (cbs) cbs(res?.data?.data)
        }
        if (cbe) cbe(res)
      })
    } catch (error) {
      console.error('🚀 ~ fetchLocation ~ error:', error)
    }
  }, [])

  const fetchDetailScore = useCallback(async (id: string, cbs?: (res: any) => void, cbe?: (res: any) => void) => {
    try {
      await axios.get(`${API_PAYX}/scores/${id}`).then((res) => {
        if (res.status === 200 || res.status === 201) {
          if (cbs) cbs(res?.data?.data)
        }
        if (cbe) cbe(res)
      })
    } catch (error) {
      console.error('🚀 ~ fetchLocation ~ error:', error)
    }
  }, [])

  const fetchSearchListScore = useCallback(async (payload: any, cbs?: (res: any) => void, cbe?: (res: any) => void) => {
    try {
      await axios.post(`${API_PAYX}/scores/search`, payload).then((res) => {
        if (res.status === 201 || res.status === 200) {
          if (cbs) cbs(res?.data?.data)
        }
        if (cbe) cbe(res)
      })
    } catch (error) {
      console.error('🚀 ~ fetchLocation ~ error:', error)
    }
  }, [])

  const fetchSearchHistoryTransaction = useCallback(
    async (payload: any, cbs?: (res: any) => void, cbe?: (res: any) => void) => {
      try {
        await axios.post(`${API_PAYX}/transactionPredicts/search`, payload).then((res) => {
          if (res.status === 201 || res.status === 200) {
            if (cbs) cbs(res?.data?.data)
          }
          if (cbe) cbe(res)
        })
      } catch (error) {
        console.error('🚀 ~ fetchLocation ~ error:', error)
      }
    },
    [],
  )

  return {
    fetchData,
    fetchInfoGame,
    fetchDetailScore,
    fetchSearchListScore,
    fetchSearchHistoryTransaction,
  }
}

export default useGetBunnyGame
